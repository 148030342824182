import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { LANGUAGE } from './Languages'
import { Collapse } from '../components/Collapse'

const Container = styled.div`
    width: 100%;
    display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

const Title = styled.h1`
    font-size: 40px;
    font-weight: bold;
    color: var(--text-color);
    width: 100%;
    text-align: center;
    display: block;
`

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
`

const Col = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`

const Divider = styled.hr`
  width: 700px;
  border: none;
  height: 1px;
  background-color: var(--border-color);
`

export const FAQ = () => {
    const {i18n} = useTranslation()
    const returnedFAQ = (): JSX.Element | undefined => {
        if (i18n.language == LANGUAGE.EN) {
            return (
                <>
                    <Collapse collapsed={true} question='How to connect the application to your relatives TV?'>
                        <>
                            <Row>
                                <p>
                                    The first time you open the application you just need to follow the steps and accept the
                                    terms of conditions and privacy policy. Then you will land on this page:
                                    <br/><br/>
                                    Put your username. This one will be visible on your relative's tv
                                </p>
                                <img src="docIcons/usernameWrite_en.png" width="300" />
                            </Row>
                            <Col>
                                <p>
                                    Put the tv name owner. This one will be the device name from your relative <br/>
                                    To connect to your relative's tv, you will need to enter your relative's TV code. <br/>
                                    <br/>
                                    You can either directly click on the QR Code button in the app and then
                                    scan the QR code that is visible on the tv. <br/>
                                    <br/>
                                    Or you can type in the code directly in the green box
                                </p>
                                <Row>
                                    <img src="docIcons/Username_en.jpg" width="300" />
                                    <img src="docIcons/tv_en.png" width="300" />
                                </Row>
                            </Col>
                            <Col>
                                <p>
                                    If it is not the first time you open the app and you are already connected to a TV. <br/>
                                    To connect a new TV to your app, you will need to click on the top-left menu in the home page.<br/>
                                    Then click on the + Connect new Tv.<br/>
                                    <br/>
                                    At this point you just need to follow the steps from the beginning.
                                </p>
                                <Row>
                                    <img src="docIcons/drawerMenu_en.png" width="300" />
                                    <img src="docIcons/newTv_en.png" width="300" /><br/><br/>
                                </Row>
                            </Col>
                        </>
                    </Collapse>
                    <Divider />
                    <Collapse collapsed={true} question='How is my data stored?'>
                        <Row>
                            <p>
                                All the medias that you send to your relatives are stored in our database and they
                                are automatically removed after 14 days to not exceed the storage quota that you have.
                                <br/><br/>
                            </p>
                        </Row>
                    </Collapse>
                    <Divider />
                    <Collapse collapsed={true} question='Can I delete my account?'>
                        <>
                            <p>
                                Your session is short-lived and can be removed by clicking on the Account settings button:<br/>
                                <br/>
                                A pop up will appear and the bottom you'll find the delete button. This is irreversible
                                and every media you uploaded will be removed from our database.
                            </p>
                            <Row>
                                <img src={"docIcons/accountSettings_en.png"} width="300" />
                                <img src={'docIcons/accountDelete_en.png'} width="300" />
                            </Row>
                        </>
                    </Collapse>
                </>)
        }
    }

    return (
        <Container>
            <Title>Frequently Asked Question</Title>
            {returnedFAQ()}
        </Container>
    )
}