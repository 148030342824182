import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export enum LANGUAGE {
    EN = 'en',
    FR = 'fr',
    NL = 'nl'

}
const Container = styled.div`
    background-color: transparent;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex-grow: 1;
`

const LanguageButton = styled.button<{isSelected: boolean}>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: auto;
  padding: 0 20px;
  border: none;
  background: white;
  text-transform: lowercase;
  font-size: 12px;
  cursor: pointer;
  color: ${({isSelected}) => isSelected ? 'var(--primary-light)' : 'var(--text-color)'};
  font-weight: 500;
  border-radius: 0;
  // border-bottom: 2px solid;
  // border-bottom-color: ${({isSelected}) => isSelected ? 'var(--primary)' : 'transparent'};
  position: relative;
  &:after {
    content:'';
    display: block;
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    width: 8px;
    border-radius: 2px;
    background: ${({isSelected}) => isSelected ? 'var(--primary)' : 'transparent'};
  }
  &:hover {
    background: #F4F4F6;
    color: rgba(0,0,0,1);
  }
`

export const Languages = () => {
    const { i18n } = useTranslation()
    console.log('l', i18n.language) // get the language

    const changeLanguage =  (lang: string) => {
        i18n.changeLanguage(lang)
            .then(r => console.log('change language success'))
            .catch(console.error)
    }

    return (
        <Container>
            <LanguageButton onClick={() => changeLanguage(LANGUAGE.EN)} isSelected={i18n.language === LANGUAGE.EN}>{LANGUAGE.EN}</LanguageButton>
            <LanguageButton onClick={() => changeLanguage(LANGUAGE.FR)} isSelected={i18n.language === LANGUAGE.FR}>{LANGUAGE.FR}</LanguageButton>
            <LanguageButton onClick={() => changeLanguage(LANGUAGE.NL)} isSelected={i18n.language === LANGUAGE.NL}>{LANGUAGE.NL}</LanguageButton>
        </Container>
    )
}