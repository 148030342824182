import React from "react";
import styled from "styled-components";

interface CollapseProps {
    collapsed: boolean
    question: string
    children?: JSX.Element
}
const Question = styled.div<{$isCollapsed: boolean}>`
 
  cursor: pointer;
  padding: 8px 0;
  display: flex; 
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-size: 20px;
    font-weight: bold;
    color: var(--text-color);
    width: 680px; 
    margin: 0;
  }
  svg {
    height: 20px;
    width: auto;
    transform: ${({ $isCollapsed }) => $isCollapsed ? 'scaleY(1)' : 'scaleY(-1)'};
  }
  
  &:hover h3{
    text-shadow: 0 1px 0 rgba(0,0,0,.2);
  }
`

const Answer = styled.div<{$isCollapsed: boolean}>`
  color: var(--text-color-light);
  font-weight: normal;
  font-size: 14px;
  width: 680px;
  gap: 24px;
  height: ${({$isCollapsed}) => $isCollapsed ? '0' : 'auto'};
  transition: all ease-in 2s;
  overflow: hidden;
  p {
    margin: 0;
  }
  img {
    border: 1px solid var(--border-color);
  }
`

export const Collapse = ({collapsed, question, children}: CollapseProps) => {
    const [isCollapsed, setIsCollapsed] = React.useState(collapsed);
    return (
        <>
            <Question onClick={() => setIsCollapsed(!isCollapsed)} $isCollapsed={isCollapsed}>
                <h3>{question}</h3>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                </svg>
            </Question>
            <Answer
                $isCollapsed={isCollapsed}
                aria-expanded={isCollapsed}>
                { children }
            </Answer>
        </>
    )
}