import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
	background-color: transparent;
  p {
    font-size: 14px;
    color: var(--text-color-light);
  }
  a{
    color: var(--primary-light);
    &:hover {
      color: var(--primary-dark);
    }
  }
  span {
    font-size: 10px;
    color: var(--text-color-light);
  }
`

export const Support = () => {

    return (
        <Container>
            <p> for any problems about a bug in flowr-connect go to this portail:
                <a href={'https://jira.taktik.be/servicedesk/customer/portal/41'}> support url </a>
            </p>
            <p> legal adress:<br/> Taktik SA <br/>
                Avenue Rein Astrid, 92 <br/>
                1310 La Hulpe
            </p>
            <p>To contact the editor of the app got to <a href={'https://www.taktik.com/'}> taktik </a> or <span>+32 2 333 58 40</span></p>
            {/* TODO phone number small Size*/}
        </Container>
    )
}
