import React from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import { LANGUAGE } from './Languages'

const Container = styled.div`
	background-color: transparent;
    width: 680px;
  margin: 0 auto;
  p, ul li {
    font-size: 14px;
    color: var(--text-color-light);
  }
`

const Title = styled.h1`
    font-size: 40px;
    font-weight: bold;
    color: var(--text-color);
    width: 100%;
    text-align: center;
    display: block;
`

const Heading = styled.h3`
    font-size: 20px;
    font-weight: bold;
    color: var(--text-color);
  margin-bottom: 0;
`

export const Privacy = () => {
    const {i18n} = useTranslation()
    const returnedPrivacy = (): JSX.Element | undefined => {
        if (i18n.language == LANGUAGE.EN) {
            return (
                <Container>
                <Title>Privacy Notice - FlowR Connect</Title>

                <Heading>Who is processing your personal data?</Heading>

                <p>This Privacy Policy explains how TAKTIK SA, a Belgian limited liability company, having its
                    registered offices at
                    Avenue Reine Astrid 92, 1310 La Hulpe, Belgium and registered with the Crossroads bank for
                    enterprises under company number 0866.028.272 (“<em>Taktik</em>”) collects and uses information from users
                    of the <em>FlowR Connect</em> App for smartphone or tablet (“<em>App</em>”) in order to provide
                    the services described in the Terms of Use (“<em>Services</em>”), as well as the purpose of such collection and use.
                </p>

                <p>Taktik acts as controller of your personal data and will safeguard the information you supply and
                    use it only in accordance with the following provisions and the Terms of Use, and in compliance with applicable
                    privacy and personal data protection legislation.</p>

                <p>This Privacy Policy may be amended by Taktik from time to time and you are therefore invited to review it periodically.</p>

                <Heading>Categories of personal data processed and purposes of processing</Heading>

                <p>You acknowledge that TAKTIK may process the following personal data relating to you in the performance of the Service: </p>

                <ul>
                    <li>your chosen user name (this may be a nick name or pseudonym),</li>
                    <li>your choice of language (French, Dutch, English or German),</li>
                    <li>your acceptance of our Terms of Use and acknowledgement of this Privacy Notice and corresponding computer logs (with date and time),
                    </li>
                    <li>the residents Iptv set(s) you are connected with (including relevant QR code, ID code and base URL) as well
                        as the date and time of connection and disconnection,
                    </li>
                    <li>the content of the communications sent in connection with the use of the Service (including,
                        as the case may be images/photos, videos, text messages, content description, voice and video when using the
                        videoconference feature of the Service), the connected Iptv set(s) to which the content was sent, and
                        corresponding computer logs (with date and time),
                    </li>
                    <li>the videoconference call history,</li>
                    <li>your maximum storage capacity in GB and whether or not it is exceeded.</li>
                </ul>

                <p>Computer log files include date and time of access/operation, amount of data sent, browser,
                    operating system and IP address.</p>

                <p>You acknowledge that these personal data may be collected and processed for the purposes of
                    providing the
                    Services, creating, identifying, verifying and managing your user account, setting the preferred
                    language in the App, controlling your access rights, contacting you and answering to your queries, identifying
                    use/misuse of the Services. In addition, Taktik may process your personal data for direct marketing purposes,
                    including by proposing in-app purchases for additional functionalities and/or storage capacity (subject to
                    your prior consent where necessary).</p>

                <Heading>Legal basis for the processing</Heading>

                <p>Taktik processes the personal data on the basis of the performance of the agreement allowing the
                    use of the Service and on basis of its legitimate interests (<em>i.e.</em> answer your queries in the best way possible and improve the Service). </p>

                <br/>
                <p>Taktik may also rely on your consent to send you direct electronic marketing communication, where applicable.</p>

                <Heading>Security</Heading>

                <p>Taktik has implemented appropriate technical and organisational measures in order to safeguard
                    your personal data against any accidental or illicit destruction, accidental loss, modification, deterioration, usage, access,
                    divulgation and against any other unauthorized processing of your personal data. To guarantee such security, Taktik uses in particular encryption of communication during transport and performs periodic backups. Taktik will not make your personal data publicly available.</p>

                <Heading>Transfer of personal data</Heading>

                <p>You acknowledge that Taktik may disclose your personal data to its sub-contractors for the
                    above-mentioned
                    purposes, both inside and outside the European Economic Area, namely our server and App hosting
                    provider. You
                    acknowledge that this may involve transfers of your personal data to countries that do not
                    provide
                    an
                    adequate
                    level of protection. Appropriate contractual measures will be taken to ensure the security of
                    your
                    personal data
                    in compliance with the applicable privacy and personal data protection legislation. A copy of
                    those
                    appropriate
                    contractual measures can be provided upon request.</p>

                <Heading>Cookies</Heading>

                <p>Cookies are not used in the App. </p>

                <Heading>Links to third party websites</Heading>

                <p>The App may create links to other websites, which are not subject to this Privacy Notice. Taktik
                    recommends you
                    examine the privacy policy applicable to these other websites. Taktik assumes no responsibility
                    whatsoever for
                    the personal data and privacy practices of such websites and encourages you to review each
                    website’s
                    privacy
                    policy before disclosing any personal data.</p>

                <Heading>Duration of retention</Heading>

                <p>Steps will be taken to ensure that your personal data is accurate, kept up to date and not kept
                    for
                    longer than
                    is necessary. Your personal data will be retained for as a long as you are a user of the App and
                    will be
                    suppressed within six (6) months after you uninstall the App, except where Taktik has a specific
                    legitimate
                    interest in keeping your personal data for a longer duration (e.g. to the extent that the data
                    would
                    be
                    required
                    to comply with a legal obligation or in the framework of a criminal complaint or a
                    litigation). </p>

                <p>With respect to the content shared via the Services, you can decide to suppress the content at
                    any
                    time.
                    Each
                    piece of content will be automatically suppressed after a period of 180 calendar days.</p>

                <Heading>Your rights</Heading>

                <p>You are entitled to access to your personal data, as collected and processed by Taktik, and
                    request
                    the
                    modification or suppression of personal data relating to you if it is incorrect or unnecessary.
                    You
                    also
                    have
                    the right to ask for the restriction of the processing or to object to the processing as well as
                    the
                    right to
                    data portability (as the case may be). When applicable, you have the right to withdraw consent
                    at
                    any
                    time,
                    without affecting the lawfulness of processing based on consent made prior to such
                    withdrawal. </p>

                <p>To exercise these rights, please send an email to Taktik at the address <a
                    href="mailto:privacy@taktik.be">privacy@taktik.be</a> or at the postal address TAKTIK SA, Avenue
                    Reine
                    Astrid 92, 1310 La Hulpe, Belgium, together with a copy of a proof of identity. </p>

                <p>In addition, you may object to the processing of your personal data for direct marketing
                    purposes, as
                    the
                    case
                    may be, at any time, free of charge and without having to provide a justification by following
                    the
                    abovementioned procedure. </p>

                <p>You also have a right to lodge a complaint with a supervisory authority (in particular in the
                    Member
                    State of the
                    European Union of your usual place of residence, place of work or the place where the violation
                    occurred) if you
                    consider that the processing of personal data related to you infringes the data protection
                    legislation.</p>

                <Heading>Modification</Heading>

                <p>Taktik expressly reserves the right to modify this Privacy Notice and you undertake to regularly
                    review
                    the
                    Privacy Notice. By amending the Privacy Notice, Taktik will take your legitimate interests in
                    consideration. You
                    will receive an in-app notification if the Privacy Notice is modified. By continuing to actively
                    using
                    the App
                    or the Services after such notification, you acknowledge that you have read the modifications to
                    the
                    Privacy
                    Notice.</p>

                <Heading>Contact information</Heading>

                <p>If you have any questions or concerns regarding the App or this Privacy Notice, please feel free
                    to
                    contact us at
                    :</p>

                <p>TAKTIK SA, Avenue Reine Astrid 92, 1310 La Hulpe, Belgium, registered with the Crossroads bank
                    for
                    enterprises
                    under company number 0866.028.272.</p>

                <br/>

                <p>E-mail: <a href="mailto:privacy@taktik.be">privacy@taktik.be</a></p>

                <br/>

                <p><em>Version 1.0 - Last update : 22 June 2021.</em></p>
            </Container>)
        }
        if (i18n.language == LANGUAGE.FR) {
            return (<>
                    <p><strong>Politique de confidentialité - FlowR Connect</strong></p>

                    <Heading>Qui traite vos données à caractère personnel ?</Heading>

                    <p>La présente Politique de confidentialité explique comment TAKTIK SA, société anonyme belge ayant
                        son
                        siège social à l'avenue Reine Astrid, 92, 1310 La Hulpe, Belgique et enregistrée auprès de la
                        Banque-Carrefour des Entreprises sous le numéro d'entreprise 0866.028.272 ("Taktik"), collecte
                        et
                        utilise les informations des utilisateurs de l'App FlowR Connect pour smartphone ou tablette
                        ("l'App") afin de fournir les services décrits dans les Conditions d'utilisation ("les
                        Services"),
                        ainsi que la finalité de cette collecte et de cette utilisation. </p>

                    <p>En tant que responsable du traitement de vos données à caractère personnel, Taktik protégera les
                        informations que vous fournissez et les utilisera exclusivement selon les dispositions ci-après
                        et
                        conformément aux Conditions d'utilisation, en conformité avec la législation applicable en
                        matière
                        de confidentialité et de protection des données à caractère personnel.</p>

                    <p>La présente Politique de confidentialité étant susceptible d'être modifiée ponctuellement par
                        Taktik,
                        veuillez la consulter périodiquement.</p>

                    <Heading>Catégories de données à caractère personnel traitées et finalités du
                        traitement</Heading>

                    <p>Vous reconnaissez que TAKTIK est autorisée à traiter les données à caractère personnel suivantes
                        vous
                        concernant dans le cadre de l'exécution du Service : </p>

                    <ul>
                        <li>le nom d'utilisateur que vous avez choisi (il peut s'agir d'un surnom ou d'un pseudonyme),
                        </li>
                        <li>votre choix de langue (français, néerlandais, anglais ou allemand),</li>
                        <li>votre acceptation de nos Conditions d'utilisation et la prise de connaissance de la présente
                            Politique de confidentialité et des fichiers-journaux (horodatés) correspondants,
                        </li>
                        <li>le ou les postes IPTV des résidents auxquels vous êtes connecté (y compris le code QR, le
                            code
                            d'identification et l'URL de base correspondants) ainsi que la date et l'heure de connexion
                            et
                            de déconnexion,
                        </li>
                        <li>le contenu des communications envoyées dans le cadre de l'utilisation du Service (y compris,
                            le
                            cas échéant, les images/photos, les vidéos, les messages textuels, les descriptions de
                            contenu,
                            la voix et la vidéo lors de l'utilisation de la fonction de visioconférence du Service), le
                            ou
                            les postes IPTV connectés auxquels le contenu a été envoyé, ainsi que les fichiers-journaux
                            (horodatés) correspondants,
                        </li>
                        <li>l'historique des appels en visioconférence,</li>
                        <li>votre capacité de stockage maximale en GB et si elle est dépassée ou non.</li>
                    </ul>

                    <p>Les fichiers-journaux comprennent la date et l'heure d'accès/de fonctionnement, la quantité de
                        données envoyées, le navigateur, le système d'exploitation et l'adresse IP.</p>

                    <p>Vous reconnaissez que ces données à caractère personnel peuvent être collectées et traitées dans
                        le
                        but de fournir les Services, de créer, d'identifier, de vérifier et de gérer votre compte
                        utilisateur, de définir la langue préférée dans l'App, de contrôler vos droits d'accès, de vous
                        contacter et de répondre à vos questions, et d'identifier l'utilisation/la mauvaise utilisation
                        des
                        Services. De plus, Taktik est autorisée à traiter vos données à caractère personnel à des fins
                        de
                        marketing direct, notamment en proposant des achats dans l'App pour des fonctionnalités et/ou de
                        la
                        capacité de stockage supplémentaire (sous réserve de votre consentement préalable le cas
                        échéant).</p>

                    <Heading>Base juridique du traitement</Heading>

                    <p>Taktik traite les données à caractère personnel sur la base de l'exécution de l'accord permettant
                        l'utilisation du Service et sur la base de ses intérêts légitimes (c'est-à-dire offrir la
                        meilleure
                        réponse à vos demandes et améliorer le Service). </p>

                    <br/>

                    <p>Taktik peut également s'appuyer sur votre consentement pour vous envoyer des communications
                        marketing
                        électroniques directes, le cas échéant.</p>

                    <Heading>Sécurité </Heading>

                    <p>Taktik a mis en place des mesures techniques et organisationnelles appropriées afin de protéger
                        vos
                        données à caractère personnel contre toute destruction accidentelle ou illicite, perte
                        accidentelle,
                        modification, détérioration, utilisation, accès, divulgation et contre tout autre traitement non
                        autorisé. Pour garantir cette sécurité, Taktik utilise notamment le cryptage des communications
                        pendant le transport et effectue des sauvegardes périodiques. Taktik ne rendra pas vos données à
                        caractère personnel accessibles au public.</p>

                    <Heading>Transfert de données à caractère personnel</Heading>

                    <p>Vous reconnaissez que Taktik peut divulguer vos données à caractère personnel à ses
                        sous-traitants
                        aux fins mentionnées ci-dessus, à l'intérieur et en dehors de l'Espace Économique Européen,
                        notamment notre fournisseur d'hébergement du serveur et de l'App. Vous reconnaissez que cette
                        divulgation peut impliquer des transferts de vos données à caractère personnel vers des pays qui
                        n'offrent pas un niveau de protection adéquat. Des mesures contractuelles appropriées seront
                        prises
                        afin de sécuriser vos données à caractère personnel conformément à la législation applicable en
                        matière de protection de la vie privée et des données personnelles. Une copie de ces mesures
                        contractuelles appropriées peut être fournie sur demande.</p>

                    <Heading>Cookies</Heading>

                    <p>L'App n'utilise aucun cookies. </p>

                    <Heading>Liens vers des sites web de tiers</Heading>

                    <p>L'App peut créer des liens vers d'autres sites web non soumis à la présente Politique de
                        confidentialité. Taktik vous recommande de lire la politique de confidentialité applicable à ces
                        autres sites web. Taktik n'assume aucune responsabilité quant aux pratiques de ces sites web en
                        matière de vie privée et de protection des données à caractère personnel et vous encourage à
                        consulter la politique de confidentialité de chaque site web avant de divulguer toute donnée à
                        caractère personnel.</p>

                    <Heading>Durée de la conservation</Heading>

                    <p>Des mesures seront prises pour garantir l'exactitude et la mise à jour de vos données à caractère
                        personnel ainsi que leur conservation pendant le temps strictement nécessaire. Vos données à
                        caractère personnel seront conservées aussi longtemps que vous utiliserez l'App et seront
                        supprimées
                        dans les six (6) mois suivant la désinstallation de l'App, sauf si Taktik possède un intérêt
                        légitime spécifique à conserver plus longtemps vos données à caractère personnel (notamment si
                        ces
                        données lui sont nécessaires pour se conformer à une obligation légale ou dans le cadre d'une
                        plainte pénale ou d'un litige). </p>

                    <p>En ce qui concerne le contenu partagé via les Services, vous pouvez décider de le supprimer à
                        tout
                        moment. Chaque élément de contenu sera automatiquement supprimé après une période de 180 jours
                        calendrier.</p>

                    <Heading>Vos droits</Heading>

                    <p>Vous avez le droit d'accéder à vos données à caractère personnel collectées et traitées par
                        Taktik et
                        de demander leur modification ou leur suppression si elles s'avèrent incorrectes ou inutiles.
                        Vous
                        avez également le droit de demander la limitation du traitement ou de vous opposer au traitement
                        ainsi que le droit à la portabilité des données (le cas échéant). Le cas échéant, vous avez le
                        droit
                        de retirer votre consentement à tout moment, sans que cette décision n'affecte la légalité du
                        traitement fondé sur le consentement donné avant ce retrait. </p>

                    <p>Pour exercer ces droits, veuillez envoyer un e-mail à Taktik à l'adresse <a
                        href="mailto:privacy@taktik.be">privacy@taktik.be</a> ou à l'adresse postale TAKTIK SA, avenue
                        Reine
                        Astrid, 92, 1310 La Hulpe, Belgique, accompagné d'une copie d'une pièce d'identité. </p>

                    <p>En outre, vous pouvez vous opposer au traitement de vos données à caractère personnel à des fins
                        de
                        marketing direct, le cas échéant, à tout moment, gratuitement et sans justification nécessaire,
                        conformément à la procédure décrite ci-dessus. </p>

                    <p>Vous avez également le droit d'introduire une plainte auprès d'une autorité de contrôle (en
                        particulier dans l'État membre de l'Union européenne de votre lieu de résidence habituel, de
                        votre
                        lieu de travail ou du lieu où la violation s'est produite) si vous estimez que le traitement des
                        données à caractère personnel vous concernant enfreint la législation sur la protection des
                        données.</p>

                    <Heading>Modification</Heading>

                    <p>Taktik se réserve expressément le droit de modifier la présente Politique de confidentialité.
                        Vous
                        vous engagez dès lors à consulter régulièrement cette dernière. Toute modification de la
                        Politique
                        de confidentialité opérée par Taktik tiendra compte de vos intérêts légitimes. Elle sera par
                        ailleurs notifiée dans l'App. En continuant à utiliser activement l'App ou les Services après
                        cette
                        notification, vous reconnaissez avoir pris connaissance des modifications apportées à la
                        Politique
                        de confidentialité.</p>

                    <Heading>Informations de contact</Heading>

                    <p>En cas de question ou de préoccupation concernant l'App ou la présente Politique de
                        confidentialité,
                        veuillez nous contacter à :</p>

                    <p>TAKTIK SA, avenue Reine Astrid, 92, 1310 La Hulpe, Belgique, inscrite à la Banque-Carrefour des
                        Entreprises sous le numéro d'entreprise 0866.028.272.</p>

                    <br/>

                    <p>E-mail: <a href="mailto:privacy@taktik.com">privacy@taktik.com</a></p>

                    <br/>


                    <p><em>Version 1.0 - Dernière mise à jour : 22 juin 2021.</em></p>
                </>
            )
        }
        if (i18n.language == LANGUAGE.NL) {
            return (
                <>
                    <p><strong>Privacyverklaring - FlowR Connect</strong></p>

                    <Heading>Wie verwerkt uw persoonsgegevens?</Heading>

                    <p>In dit privacybeleid wordt uiteengezet op welke wijze TAKTIK nv, een Belgische vennootschap met
                        beperkte aansprakelijkheid en maatschappelijke zetel gevestigd in de Koningin Astridlaan 92 te
                        1310 Terhulpen, België, geregistreerd in de Kruispuntbank van de Ondernemingen onder het
                        ondernemingsnummer 0866.028.272 ('<strong>Taktik</strong>') gegevens van gebruikers van de <em>FlowR
                            Connect</em> App voor smartphone of tablet ('<strong>App</strong>') verzamelt en gebruikt om
                        de diensten te verlenen die worden beschreven in de Gebruiksvoorwaarden
                        ('<strong>Diensten</strong>'), en ook wat het doel van dergelijke verzameling en dergelijk
                        gebruik is. </p>

                    <p>Taktik fungeert als verwerkingsverantwoordelijke van uw persoonsgegevens en zal de informatie die
                        u verstrekt, beschermen en enkel gebruiken in overeenstemming met de volgende bepalingen van de
                        Gebruiksvoorwaarden, en in overeenstemming met de toepasselijke wetgeving betreffende privacy en
                        gegevensbescherming.</p>

                    <p>Dit privacybeleid kan van tijd tot tijd worden aangepast door Taktik. Bijgevolg wordt u verzocht
                        om het beleid regelmatig na te kijken.</p>

                    <Heading>Categorieën van persoonsgegevens en verwerkingsdoeleinden</Heading>

                    <p>U erkent dat TAKTIK de volgende persoonsgegevens die verband houden met u, kan verwerken bij de
                        uitvoering van de Dienst: </p>

                    <ul>
                        <li>uw gekozen gebruikersnaam (dit kan een bijnaam of pseudoniem zijn),</li>
                        <li>de taal die u hebt gekozen (Frans, Nederlands, Engels of Duits),</li>
                        <li>uw aanvaarding van onze Gebruikersvoorwaarden en uw erkenning van dit Privacybeleid en
                            overeenkomstige computerlogbestanden (met datum en tijdstip),
                        </li>
                        <li>de IPTV-set(s) waarmee u verbonden bent (waaronder relevante QR-code, ID-code en basis URL)
                            evenals de datum en het tijdstip van verbinding en verbreking van de verbinding,
                        </li>
                        <li>de inhoud van de communicaties die worden verzonden in samenhang met het gebruik van de
                            Dienst (waaronder, desgevallend, afbeeldingen/foto's, video's, tekstmeldingen,
                            inhoudbeschrijving, stem en video bij gebruik van de functie voor videoconferentie van de
                            Dienst), de verbonden IPTV-set(s) waarnaar de inhoud was verstuurd, en overeenkomstige
                            computerlogbestanden (met datum en tijdstip),
                        </li>
                        <li>de historiek van videoconferenties,</li>
                        <li>uw maximaal opslagvermogen in GB en het feit of dat maximum overschreden is of niet.</li>
                    </ul>

                    <p>Computerlogbestanden bevatten datum en tijdstip van toegang/verrichting, de hoeveelheid verzonden
                        data, de browser, het besturingssysteem en het IP-adres.</p>

                    <p>U erkent dat deze persoonsgegevens kunnen worden verzameld en verwerkt met het oog op de
                        uitvoering van de Diensten, de aanmaak, de identificatie, de verificatie en het beheer van uw
                        gebruikersaccount, de instelling van uw favoriete taal in de app, de controle van uw
                        toegangsrechten, om contact met u op te nemen en uw vragen te beantwoorden, of om
                        gebruik/misbruik van de Diensten te identificeren. Bijkomend kan Taktik uw persoonsgegevens
                        verwerken voor doeleinden van direct marketing, waaronder door in-app aankopen voor extra
                        functies en/of opslagvermogen aan te bieden (onderworpen aan uw voorafgaande toestemming waar
                        nodig).</p>

                    <Heading>Rechtsgrond voor de verwerking</Heading>

                    <p>Taktik verwerkt de persoonsgegevens op basis van de uitvoering van de overeenkomst die het
                        gebruik van de Dienst mogelijk maakt en op basis van haar gerechtvaardigde belangen
                        (<em>i.e.</em> uw vragen zo goed mogelijk beantwoorden en de Dienst verbeteren). </p>

                    <br/>

                    <p>Taktik kan zich ook baseren op uw toestemming om u rechtstreeks elektronische
                        marketingcommunicatie te bezorgen, waar dit van toepassing is.</p>

                    <Heading>Beveiliging </Heading>

                    <p>Taktik heeft passende technische en organisatorische maatregelen ingevoerd om uw persoonsgegevens
                        te beschermen tegen elke vernietiging, hetzij per ongeluk, hetzij onrechtmatig, elk accidenteel
                        verlies, wijziging, aantasting, gebruik, toegang, verspreiding en tegen elke andere
                        ongeoorloofde verwerking van uw persoonsgegevens. Om die beveiliging te garanderen maakt Taktik
                        gebruik van specifieke versleuteling van communicatie tijdens hun transport en maakt ze
                        periodiek back-ups. Taktik zal uw persoonsgegevens niet openbaar maken.</p>

                    <Heading>Doorgifte van persoonsgegevens</Heading>

                    <p>U erkent dat Taktik uw persoonsgegevens kan verstrekken aan haar onderaannemers voor de
                        bovenvermelde doeleinden, zowel binnen de Europese Economische Ruimte als erbuiten, namelijk de
                        hosting provider van onze server en App. U erkent dat dit kan impliceren dat uw persoonsgegevens
                        worden doorgegeven aan landen waar geen adequaat beschermingsniveau wordt geboden. Adequate
                        contractuele maatregelen zullen worden getroffen om de veiligheid van uw persoonsgegevens te
                        verzekeren in overeenstemming met de toepasselijke wetgeving betreffende privacy en
                        gegevensbescherming. Een kopie van deze adequate contractuele maatregelen kan worden afgeleverd
                        op verzoek.</p>

                    <Heading>Cookies</Heading>

                    <p>Er worden geen cookies gebruikt in de App. </p>

                    <Heading>Links naar websites van derde partijen</Heading>

                    <p>De App kan links naar andere websites bevatten, die niet onder dit Privacybeleid vallen. Taktik
                        raadt u aan om het privacybeleid dat van toepassing is op deze andere websites, te bekijken.
                        Taktik neemt geen enkele verantwoordelijkheid op zich voor de persoonsgegevens en
                        privacypraktijken van die websites en moedigt u aan om het privacybeleid van elk van die
                        websites na te kijken alvorens persoonsgegevens mee te delen.</p>

                    <Heading>Opslagperiode</Heading>

                    <p>Er zullen stappen ondernomen worden om te verzekeren dat uw persoonsgegevens accuraat en actueel
                        zijn en dat ze niet langer dan nodig is, worden bewaard. Uw persoonsgegevens zullen bewaard
                        blijven zolang u een gebruiker bent van de App en ze zullen verwijderd worden binnen zes (6)
                        maanden nadat u de installatie van de App ongedaan hebt gemaakt, tenzij Taktik een
                        gerechtvaardigd belang heeft om uw persoonsgegevens langer te bewaren (bijv. voor zover de
                        gegevens nodig zouden zijn om te voldoen aan een wettelijke verplichting of in het kader van een
                        strafrechtelijke klacht of een rechtsgeding). </p>

                    <p>Met betrekking tot de inhoud die via de Diensten wordt gedeeld, kunt u op elk moment besluiten de
                        inhoud te wissen. Elke inhoud zal automatisch worden gewist na een periode van 180
                        kalenderdagen.</p>

                    <Heading>Uw rechten</Heading>

                    <p>U hebt recht op toegang tot uw persoonsgegevens, zoals verzameld en verwerkt door Taktik, en u
                        kunt vragen om persoonsgegevens die op u betrekking hebben te wijzigen of te verwijderen, indien
                        deze onjuist of onnodig zijn. U hebt ook het recht om de beperking van de verwerking te vragen
                        of om bezwaar te maken tegen de verwerking, net zoals u recht hebt op de overdraagbaarheid van
                        de gegevens (naargelang het geval). Indien van toepassing, hebt u het recht om uw toestemming te
                        allen tijde in te trekken, zonder dat dit gevolgen heeft voor de rechtmatigheid van de
                        verwerking op basis van de toestemming die vóór de intrekking is gegeven. </p>

                    <p>Om deze rechten uit te oefenen kunt u een e-mail verzenden naar Taktik op het volgende adres: <a
                        href="mailto:privacy@taktik.com">privacy@taktik.com</a> of het postadres TAKTIK nv, Koningin
                        Astridlaan 92, 1310 Terhulpen, België, samen met een kopie van een identiteitsbewijs. </p>

                    <p>Bovendien kunt u zich op elk ogenblik, kosteloos en zonder opgave van redenen, verzetten tegen de
                        verwerking van uw persoonsgegevens voor doeleinden van directmarketing, naargelang het geval,
                        door de bovengenoemde procedure te volgen. </p>

                    <p>U hebt ook het recht om een klacht in te dienen bij een toezichthoudende autoriteit (in het
                        bijzonder in de lidstaat van de Europese Unie waar u gewoonlijk verblijft, werkt of waar de
                        inbreuk heeft plaatsgevonden) indien u van mening bent dat de verwerking van uw persoonsgegevens
                        in strijd is met de wetgeving inzake gegevensbescherming.</p>

                    <Heading>Wijziging</Heading>

                    <p>Taktik behoudt zich uitdrukkelijk het recht voor om deze Privacyverklaring te wijzigen en u wordt
                        verzocht om deze Privacyverklaring regelmatig na te kijken. Bij de wijziging van de
                        Privacyverklaring zal Taktik uw gerechtvaardigde belangen in aanmerking nemen. U zult een in-app
                        melding ontvangen, indien de Privacyverklaring gewijzigd is. Door de App of de Diensten actief
                        te blijven gebruiken na dergelijke melding, erkent u dat u de wijzigingen aan de
                        Privacyverklaring hebt gelezen.</p>

                    <Heading>Contactgegevens</Heading>

                    <p>Hebt u vragen of bekommernissen in verband met de App of deze Privacyverklaring, gelieve dan
                        contact met ons op te nemen:</p>

                    <p>TAKTIK nv, Koningin Astridlaan 92, 1310 Terhulpen, België, geregistreerd in de Kruispuntbank van
                        de Ondernemingen onder het ondernemingsnummer 0866.028.272.</p>

                    <br/><br/>

                    <p>E-mail: <a href="mailto:privacy@taktik.com">privacy@taktik.com</a></p>

                    <br/><br/>


                    <p><em>Versie 1.0 - Laatste update: 22 juni 2021.</em></p>
                </>)
        }
    }

    return (
        <Container>
            {returnedPrivacy()}
        </Container>
    )
}
